import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import b from "../../image/parents/b.png";
import c from "../../image/parents/c.jpg";
import d from "../../image/parents/d.png";
import e from "../../image/parents/e.png";
import f from "../../image/parents/f.jpg";
import s1 from "../../image/students/s1.jpg";
import s2 from "../../image/students/s2.jpg";
import s3 from "../../image/students/s3.jpg";
import paTopIcon from "../../image/newImg/pa.png";
import "./Content.css";

function Parents(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
              alt=""
            />
            Parents' Voice
          </div>
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={b}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
                alt=""
              />
            </div>
          </div>
          <div className={`voice_text`}>
            <p style={{ marginTop: "1rem" }}>
              <b>Yashwant Bharadwaj Vangipurapu (X-D)</b>
            </p>
            <p style={{ marginTop: "1rem" }}>
              I am Dr. Neela, mother of Yashwant Bharadwaj Vangipurapu of class
              10th D. My son joined the School last year in 9th grade here (
              Hermann Gmeiner School, Varanasi). As my son is passionate towards
              cricket and average in studies,he was encouraged by teachers about
              his passion and helped in make balance in sports as well as
              studies. Now my son is able to focus on both equally. I am very
              much thankful to all the teachers and the Principal, Dr A P Gaur
              who really understood his need and encouraged him to go into the
              positive direction in his life.
            </p>
          </div>

          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Janhavi Yadav (IV)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                We are Mr. & Mrs. Yadav , parents of Janhavi Yadav. My daughter
                Janhavi Yadav is a part of Hermann Gmeiner family since 2014 .
                We admitted her when she was in class 4th with the hope that
                from good she would perform her best and according to us this
                could be one of the best way where she could achieve and reach
                to a great milestone. And yes she emerged as one of the toppers
                in 2020-21 And now she's in class 12th (PBC).Now, we can proudly
                say that she's doing great here. Well equipped classes and
                facilities, great teachers and guidance enhanced her to reach
                there.We are happy that we took a great decision . A great
                school and yes it's a loving home for every child!
              </p>
            </div>
            <div className="parentimage">
              <img
                src={c}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className="parentimage">
              <img
                src={d}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Avantika Giri (X)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am Aarti Giri, mother of Avantika Giri,who is studying in
                Hermann Gmeiner School, Varanasi from Class LKG and at present
                she is in class 10 and I am very happy from this school as my
                child has learnt a lot and she has been given many opportunities
                to improve her speaking skills as school has many activities to
                ensure the development of child in different fields.
              </p>
            </div>
          </div>
          {/*  */}
          {/* students' voice */}
          <div className={`f-2 f-500 mt-5`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{
                width: "4rem",
                marginRight: "1rem",
                marginTop: "30px",
              }}
              alt=""
            />
            Students' Voice
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={s1}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
                alt=""
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Prashant Singh</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am cadet Prashant Singh of 7 UP NAVAL UNIT NCC BHU I am really
                grateful to my school for giving me the chance to join the NCC A
                Certificate. Now I am selected for B certificate from BHU while
                continuing my studies at Hermann Gmeiner School in class 11. My
                honorable principal sir (Dr. A.P Gaur) and respected ANO sir
                (MR. YOGESH KUMAR SINGH) will be remembered by me for supporting
                me to fulfill my dream.{" "}
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Shivangi Mishra (X C) </b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My name is Shivangi Mishra. I am studying in Hermann Gmeiner
                School, Varanasi since 2013. Now, I am in class 10 C. I am very
                happy to say that it is a big opportunity for me to become a
                part of HGS because it provides us a platform where students get
                to know their qualities and can discover their hidden
                talent.Principal sir is our inspiration and teachers are
                sculptures who give shape to students. In my opinion, my school
                is the best.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={s2}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Parents;
