import axios from "axios";
//changes
export const getStudentTCData = async () => {
  try {
    const response = await axios.get(
      "https://cors-fix.viosa.in/https://eschool.appsosindia.org/views/api/std_tc_list.php?school_code=HGSV"
    );
    return response.data.student_tcinfo;
  } catch (error) {
    console.log(error);
  }
};
